import React from 'react';
import { Root, Routes } from 'react-static';

import { ThemeProvider } from 'styled-components';
import { modularScale } from 'polished';

const images = [
  'CWB64609-2k4_sm.jpg',
  'CWB64611-2k4_sm.jpg',
  'WFG21427_sm.jpg',
  'WFG21181_sm.jpg',
  'WFH04705_sm.jpg',
  'WFG22038_sm.jpg',
  'bessvatn-1_sm.jpg',
  'WFG21972_sm.jpg',
];

const pickBgImage = () => {
  const x = Math.floor(Math.random() * images.length);
  return `img/${images[x]}`;
};

const theme = {
  acColor: '#edbb2f',
  bgColor: '#3f4447',
  fgColor: '#f4faff',
  textFontFamily: 'jaf-bernina-sans-condensed',
  textFontWeight: '400',
  headFontFamily: 'jaf-bernina-sans-comp',
  headFontWeight: '700',
  fontSize: (steps) => modularScale(steps,
    '1.2rem',
    'perfectFourth'),
  bgImage: 'img/WFG21427_sm.jpg',
};

class App extends React.Component {
  render() {
    return (
      <Root>
        <ThemeProvider theme={theme}>
          <Routes/>
        </ThemeProvider>
      </Root>
    );
  }
}

export default App;
