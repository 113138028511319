// Imports
import plugin0 from '/opt/build/repo/node_modules/react-static-plugin-react-router/browser.api.js'

// Plugins
const plugins = [{
  location: "/opt/build/repo/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/opt/build/repo/node_modules/react-static-plugin-react-router",
  plugins: [],
  hooks: plugin0({})
},
{
  location: "/opt/build/repo",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins